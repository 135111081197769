import { useNavigate } from "react-router-dom";
import { FC, memo, useRef, useState, useCallback } from "react";
import {
  Button,
  Container,
  FileItem,
  FileUpload,
  DropAcceptedFunc,
  useWillUnmount,
  useDidUpdate,
  notification,
  Nullable,
  useFileQueue,
  useFetch,
} from "@epcnetwork/core-ui-kit";

import {
  getPipelineRoute,
  getAcceptedFilesText,
  getSupportedFormats,
  onFileRedirect,
  getFileUuidName,
} from "utils";
import { useTracking } from "hooks";
import { SMARTLOOK_EVENTS } from "constants/smartlook.constants";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { getDatabasesUpdates, postOverlapInsightFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { OverlapInsightFileItem, PipelineOverlapInsightData } from "./overlap-insight.types";
import { supportedFormats, getInitialData } from "./overlap-insight.constants";
import { OverlapInsightFormModal } from "./form-modal/overlap-insight-form-modal";

import styles from "./overlap-insight.module.css";

const OverlapInsightPage: FC = memo(() => {
  const componentIsMounted = useRef(true);
  const navigate = useNavigate();

  const { pipelineSubmitEvent } = useTracking();

  const pipeline = getPipelineRoute("Overlap Insight");

  const { payload } = useFetch(getDatabasesUpdates);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitAtOnce,
    hasAllSuccessFiles,
  } = useFileQueue<OverlapInsightFileItem, PipelineOverlapInsightData>(
    pipeline.title,
    postOverlapInsightFile,
  );

  useWillUnmount(() => {
    componentIsMounted.current = false;
  });

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const [editedFile, setEditedFile] = useState<Nullable<OverlapInsightFileItem>>(null);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: OverlapInsightFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleSubmit = () => {
    const filesInfo: PipelineOverlapInsightData["filesInfo"] = files.map((file) => {
      const { emailIndex, fileName, hasHeaders } = file.data;
      const id = getFileUuidName(file);

      return { emailIndex, filename: fileName, hasHeaders, id };
    });

    const formData = new FormData();
    formData.append("filesInfo", JSON.stringify(filesInfo));
    files.forEach((file) => {
      formData.append("files", file.originalFile, getFileUuidName(file));
    });

    submitAtOnce(
      files.map(({ id }) => id),
      formData,
    );

    pipelineSubmitEvent(SMARTLOOK_EVENTS.overlapInsight, files);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, navigate });
  };

  const handleRedirectToPipelines = () => {
    navigate(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.updates}>
          <h4>Databases updates</h4>

          <div className={styles.databasesUpdates}>
            <>
              <p className={styles.updateParagraph}>
                Buyers: <strong>{payload ? new Date(payload.buyers).toLocaleString() : "-"}</strong>
              </p>
              <p className={styles.updateParagraph}>
                Leads: <strong>{payload ? new Date(payload.leads).toLocaleString() : "-"}</strong>
              </p>
              <p className={styles.updateParagraph}>
                Iterable complainers:{" "}
                <strong>
                  {payload ? new Date(payload.iterableComplainers).toLocaleString() : "-"}
                </strong>
              </p>
              <p className={styles.updateParagraph}>
                Suppressions:{" "}
                <strong>{payload ? new Date(payload.suppressions).toLocaleString() : "-"}</strong>
              </p>
            </>
          </div>
        </div>

        <div className={styles.form}>
          <FileUpload
            className={styles.dropZone}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            maxFiles={100}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleConfigureItem}
                onEditValuesClick={handleConfigureItem}
              >
                {data.emailIndex >= 0 && (
                  <div className={styles.additionalInfo}>
                    <span>Selected email column:</span> {data.emailIndex + 1}
                  </div>
                )}
              </FileItem>
            ))}
          </div>

          {editedFile && (
            <OverlapInsightFormModal
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}
          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
              disabled={isEntityFinished && !hasAllSuccessFiles}
            >
              Cancel
            </Button>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!hasAllConfigured || files.length === 0}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
});

export { OverlapInsightPage };
