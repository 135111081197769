import { useLocation } from "react-router-dom";
import { memo, useState } from "react";
import {
  deleteEntity,
  notification,
  useFileQueueProvider,
  isEmpty,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { isPipelineListRoute } from "utils";
import { useAuthorization } from "hooks";
import { SUPPRESSION_IMPORT_PAGE } from "constants/routes.constants";
import { PipelineRoutes, PIPELINES_LIST } from "constants/pipelines.constants";
import { routes } from "config/routes.config";

export const FileQueueNotifications = memo(() => {
  const location = useLocation();
  const ability = useAuthorization();

  const [state, dispatch] = useFileQueueProvider();
  const currentLocation = PIPELINES_LIST.filter((pipeline) =>
    location.pathname.includes(pipeline.path),
  );
  const [fileSystemLocation, setFileSystemLocation] = useState<PipelineRoutes[]>(currentLocation);

  const nameFileSystem = Object.keys(state.entities)[0];
  const statusFileSystem = state.entities[nameFileSystem]?.status;
  const countFiles = state.entities[nameFileSystem]?.files.length;
  const dynamicCountTitle = countFiles === 1 ? "file" : "files";
  const isSuppressionImportPage = location.pathname.includes(SUPPRESSION_IMPORT_PAGE.path);

  useDidUpdate(() => {
    if (!isPipelineListRoute(location.pathname)) return;

    const currentLocation = PIPELINES_LIST.filter((pipeline) =>
      location.pathname.includes(pipeline.path),
    );
    setFileSystemLocation(currentLocation);
  }, [location.pathname]);

  const currentRoute = routes.filter((route) => route.path === location.pathname)[0];

  const isAuthorized =
    currentRoute?.auth && currentRoute?.authEntity
      ? ability.can("create", currentRoute.authEntity)
      : true;

  useDidUpdate(() => {
    if (!statusFileSystem || !isAuthorized) return;

    if (statusFileSystem === "finished" && isEmpty(fileSystemLocation)) {
      notification.success(
        "Success",
        `${countFiles} ${dynamicCountTitle} send to the ${nameFileSystem}`,
      );
      dispatch(deleteEntity(nameFileSystem));
    }

    if (
      statusFileSystem === "configuration" &&
      isEmpty(fileSystemLocation) &&
      !isSuppressionImportPage
    ) {
      dispatch(deleteEntity(nameFileSystem));
    }
  }, [fileSystemLocation, statusFileSystem]);

  return null;
});
