import { Nullable, SelectOption } from "@epcnetwork/core-ui-kit";

import { Pipelines } from "api/pipelines/pipelines.interface";
import { RangeOption, PipelineResponse, PipelineDetails } from "./stats.types";

export const rangeOptions: RangeOption[] = [
  { days: 7, shortLabel: "1W", longLabel: "1 week" },
  { days: 30, shortLabel: "1M", longLabel: "1 month" },
  { days: 90, shortLabel: "3M", longLabel: "3 months" },
  { days: 180, shortLabel: "6M", longLabel: "6 months" },
  { days: 365, shortLabel: "1Y", longLabel: "1 year" },
];

export const pipelineDetails: Record<Pipelines, PipelineDetails> = {
  convert: { label: "Convert", fill: "#354f52" },
  cut: { label: "Cut", fill: "#20B2AA" },
  deduplicate: { label: "Deduplicate", fill: "#ef476f" },
  diffAndCommon: { label: "D&C", fullName: "Diff and Common", fill: "#66CDAA" },
  domainPurify: { label: "Domain Purify", fill: "#2E8B57" },
  extract: { label: "Extract", fill: "#3CB371" },
  export: { label: "Export", fill: "#fcbf49" },
  ispDomainSplitter: { label: "ISP", fullName: "ISP Domain Splitter", fill: "#f95738" },
  merge: { label: "Merge", fill: "#f38375" },
  md5: { label: "MD5", fill: "#84a59d" },
  sha256: { label: "SHA256", fill: "#a98467" },
  split: { label: "Split", fill: "#f4d35e" },
  sumClicks: { label: "Sum Clicks", fill: "#ee964b" },
  warmUpSplitter: { label: "Warm-up", fullName: "Warm-up Splitter", fill: "#5F9EA0" },
  suppress: { label: "Suppression", fill: "#6495ED" },
  match: { label: "Match", fill: "#4682B4" },
  reverse: { label: "Reverse", fill: "#4169E1" },
  matchAndEliminate: { label: "M&E", fullName: "Match and Eliminate", fill: "#1E90FF" },
  overlapInsight: { label: "Overlap Insight", fullName: "Overlap Insight", fill: "#1E70FF" },
};

export const mapPipelineResponse = (data: Nullable<PipelineResponse>) => {
  return data?.map((item) => ({ ...item, ...pipelineDetails[item.name] }));
};

export const pipelineOptions: SelectOption<Pipelines>[] = [
  { label: "Convert", value: "convert" },
  { label: "Cut", value: "cut" },
  { label: "Deduplicate", value: "deduplicate" },
  { label: "Diff and Common", value: "diffAndCommon" },
  { label: "Domain Purify", value: "domainPurify" },
  { label: "Extract", value: "extract" },
  { label: "Export", value: "export" },
  { label: "ISP Domain Splitter", value: "ispDomainSplitter" },
  { label: "Merge", value: "merge" },
  { label: "MD5", value: "md5" },
  { label: "SHA256", value: "sha256" },
  { label: "Split", value: "split" },
  { label: "Sum Clicks", value: "sumClicks" },
  { label: "Warm Up Splitter", value: "warmUpSplitter" },
  { label: "Suppression", value: "suppress" },
  { label: "Match", value: "match" },
  { label: "Reverse", value: "reverse" },
  { label: "Match and Eliminate", value: "matchAndEliminate" },
  { label: "Overlap Insight", value: "overlapInsight" },
];

export const colors = [
  "#2E8B57", // Sea Green
  "#20B2AA", // Light Sea Green
  "#3CB371", // Medium Sea Green
  "#66CDAA", // Medium Aquamarine
  "#2E8B57", // Sea Green
  "#3CB371", // Medium Sea Green
  "#20B2AA", // Light Sea Green
  "#48D1CC", // Medium Turquoise
  "#40E0D0", // Turquoise
  "#AFEEEE", // Pale Turquoise

  "#87CEEB", // Sky Blue
  "#B0C4DE", // Light Steel Blue
  "#5F9EA0", // Cadet Blue
  "#6495ED", // Cornflower Blue
  "#4682B4", // Steel Blue
  "#4169E1", // Royal Blue
  "#1E90FF", // Light Sky Blue
  "#217ee2", // Blue
  "#0000CD", // Medium Blue
  "#00008B", // Dark Blue
];
