export const SMARTLOOK_EVENTS = {
  convertSubmit: "CONVERT_SUBMIT",
  cutSubmit: "CUT_SUBMIT",
  deduplicateSubmit: "DEDUPLICATE_SUBMIT",
  diffCommonSubmit: "DIFF_AND_COMMON_SUBMIT",
  domainPurifySubmit: "DOMAIN_PURIFY_SUBMIT",
  extractSubmit: "EXTRACT_SUBMIT",
  ispSplitterSubmit: "EXTRACT_SUBMIT",
  matchSubmit: "MATCH_SUBMIT",
  matchAndEliminateSubmit: "MATCH_AND_ELIMINATE_SUBMIT",
  md5Submit: "MD5_SUBMIT",
  mergeSubmit: "MERGE_SUBMIT",
  reverseSubmit: "REVERSE_SUBMIT",
  sha256Submit: "SHA256_SUBMIT",
  splitSubmit: "SPLIT_SUBMIT",
  sumClicksSubmit: "SUM_CLICKS_SUBMIT",
  suppressionSubmit: "SUPPRESSION_SUBMIT",
  warmUpSubmit: "WARM_UP_SUBMIT",
  overlapInsight: "OVERLAP_INSIGHT",
};
